import { tenantContext } from '../stateManagement/localMemory';
// import { validateViewPermissions } from '../services/stateManagement/permissions';
import ApiAxiosClientsFactory from './apiAxiosClientsFactory';
import qs from 'qs';

class JourneyApiAxiosClient {
  static viewPermissions = (context) => {
    // console.log('validateViewPermissions', context);
    // validateViewPermissions({
    //   params: context,
    //   path: context.path,
    //   method: context.method
    // });
  };
  static logResults = (response) => console.log('response', response);
  static logError = (err) => console.error('error', err);

  static async executeHooks(hookType, methodArgs, serviceName) {
    let hooks = this.hooksMap[hookType] || [];
    if (serviceName && this.hooksMap[serviceName] && this.hooksMap[serviceName][hookType]) {
      hooks = hooks.concat(this.hooksMap[serviceName][hookType]);
    }
    for (const hook of hooks) {
      await hook(methodArgs ? { ...methodArgs, serviceName } : { serviceName });
    }
  }

  static async beforeHooks(serviceName, args) {
    await this.executeHooks('before', args)
    await this.executeHooks('before', args, serviceName);
  }

  static async afterHooks(serviceName, args) {
    await this.executeHooks('after', args)
    await this.executeHooks('after', args, serviceName);
  }

  static async errorHooks(serviceName, args) {
    await this.executeHooks('error', args)
    await this.executeHooks('error', args, serviceName);
  }

  static queryFormatter(query) {
    if (!query?.query) {
      return ''
    }
    return "?" + qs.stringify(query.query, { encode: false });
  }

  static async getTenantId() {
    const theTenantContext = await tenantContext.get(0)
    return theTenantContext.tenantId
  }

  static async find(viewName, serviceName, query) {
    try {
      const tenantId = await this.getTenantId()
      const path = '/api/' + tenantId + '/' + serviceName
      await this.beforeHooks(serviceName, { viewName, query, method: 'find', path });
      const response = await ApiAxiosClientsFactory.JourneyApiAxiosClient.get(path + this.queryFormatter(query));
      await this.afterHooks(serviceName, response.data);
      return response.data;
    } catch (error) {
      await this.errorHooks(serviceName, error);
      throw error;
    }
  }

  static async get(viewName, serviceName, id, query) {
    try {
      const tenantId = await this.getTenantId()
      const path = '/api/' + tenantId + '/' + serviceName + '/' + id
      await this.beforeHooks(serviceName, { viewName, query, method: 'get', path });
      const response = await ApiAxiosClientsFactory.JourneyApiAxiosClient.get(path + this.queryFormatter(query));
      await this.afterHooks(serviceName, response);
      return response.data;
    } catch (error) {
      await this.errorHooks(serviceName, error);
      throw error;
    }
  }

  static async post(viewName, serviceName, query, body) {
    try {
      const tenantId = await this.getTenantId()
      const path = '/api/' + tenantId + '/' + serviceName
      await this.beforeHooks(serviceName, { viewName, query, method: 'post', path });
      const response = await ApiAxiosClientsFactory.JourneyApiAxiosClient.post(path + this.queryFormatter(query), body);
      await this.afterHooks(serviceName, response);
      return response.data;
    } catch (error) {
      await this.errorHooks(serviceName, error);
      throw error;
    }
  }

  static async patch(viewName, serviceName, id, query, body) {
    try {
      const tenantId = await this.getTenantId()
      const path = '/api/' + tenantId + '/' + serviceName + '/' + id
      await this.beforeHooks(serviceName, { viewName, query, method: 'patch', path });
      const response = await ApiAxiosClientsFactory.JourneyApiAxiosClient.patch(path + this.queryFormatter(query), body);
      await this.afterHooks(serviceName, response);
      return response.data;
    } catch (error) {
      await this.errorHooks(serviceName, error);
      throw error;
    }
  }

  static async delete(viewName, serviceName, id, query) {
    try {
      const tenantId = await this.getTenantId()
      const path = '/api/' + tenantId + '/' + serviceName + '/' + id
      await this.beforeHooks(serviceName, { viewName, query, method: 'delete', path });
      const response = await ApiAxiosClientsFactory.JourneyApiAxiosClient.delete(path + this.queryFormatter(query));
      await this.afterHooks(serviceName, response);
      return response.data;
    } catch (error) {
      await this.errorHooks(serviceName, error);
      throw error;
    }
  }

  static hooksBooks = {
    before: [(args) => {
      console.log('before hook to execute before any book operation', args);
    }],
    after: [(data) => {
      console.log('after hook to execute after any book operation', data);
    }],
    error: [(err) => {
      console.error('error hook to execute after any book operation error', err);
    }]
  };

  static hooksMap = {
    before: [this.viewPermissions],
    after: [],
    error: [this.logError],
    books: this.hooksBooks
  };

  static booksService = {
    find: async (query) => {
      console.log("My specific before hook for find books", query)
      const books = await this.find('books', 'books', query)
      console.log("My specific after hook for find books", books)
      return books
    },
    get: (id, query) => this.get('books', 'books', id, query),
    create: (body, query) => this.post('books', 'books', query, body),
    patch: (id, body, query) => this.patch('books', 'books', id, query, body),
    remove: (id, query) => this.delete('books', 'books', id, query),
  }

  static instructorsService = {
    find: async (query) => {
      const service = await this.find('instructors', 'instructors', query)
      return service
    },
    get: (id, query) => this.get('instructors', 'instructors', id, query),
    create: (body, query) => this.post('instructors', 'instructors', query, body),
    patch: (id, body, query) => this.patch('instructors', 'instructors', id, query, body),
    remove: (id, query) => this.delete('instructors', 'instructors', id, query),
  }

  static referenceDataMasterService = {
    find: async (query) => {
      const service = await this.find('reference-data-master', 'reference-data-master', query)
      return service
    },
    get: (id, query) => this.get('reference-data-master', 'reference-data-master', id, query),
    create: (body, query) => this.post('reference-data-master', 'reference-data-master', query, body),
    patch: (id, body, query) => this.patch('reference-data-master', 'reference-data-master', id, query, body),
    remove: (id, query) => this.delete('reference-data-master', 'reference-data-master', id, query),
  }

  static referenceDataService = {
    find: async (query) => {
      const service = await this.find('reference-data', 'reference-data', query)
      return service
    },
    get: (id, query) => this.get('reference-data', 'reference-data', id, query),
    create: (body, query) => this.post('reference-data', 'reference-data', query, body),
    patch: (id, body, query) => this.patch('reference-data', 'reference-data', id, query, body),
  }


  static skillsMapService = {
    find: async (query) => {
      const service = await this.find('skills-map', 'skills-map', query)
      return service
    },
  }

  static coreSkillsService = {
    find: async (query) => {
      const service = await this.find('core-skills', 'core-skills', query)
      return service
    },
    get: (id, query) => this.get('core-skills', 'core-skills', id, query),
    create: (body, query) => this.post('core-skills', 'core-skills', query, body),
    patch: (id, body, query) => this.patch('core-skills', 'core-skills', id, query, body),
    remove: (id, query) => this.delete('core-skills', 'core-skills', id, query),
  }

  static careerPathsService = {
    find: async (query) => {
      const service = await this.find('career-paths', 'career-paths', query)
      return service
    },
    get: (id, query) => this.get('career-paths', 'career-paths', id, query),
    create: (body, query) => this.post('career-paths', 'career-paths', query, body),
    patch: (id, body, query) => this.patch('career-paths', 'career-paths', id, query, body),
    remove: (id, query) => this.delete('career-paths', 'career-paths', id, query),
  }

  static sectionsService = {
    find: async (query) => {
      const service = await this.find('sections', 'sections', query)
      return service
    },
    get: (id, query) => this.get('sections', 'sections', id, query),
    create: (body, query) => this.post('sections', 'sections', query, body),
    patch: (id, body, query) => this.patch('sections', 'sections', id, query, body),
    remove: (id, query) => this.delete('sections', 'sections', id, query),
  }
  static coursesService = {
    find: async (query) => {
      const service = await this.find('courses', 'courses', query)
      return service
    },
    get: async (id, query) => await this.get('courses', 'courses', id, query),
    create: async (body, query) => await this.post('courses', 'courses', query, body),
    patch: async (id, body, query) => {
      return await this.patch('courses', 'courses', id, query, body)
    },
    remove: async (id, query) => await this.delete('courses', 'courses', id, query),
  }

  static studentsService = {
    find: async (query) => {
      const service = await this.find('students', 'students', query)
      return service
    },
    get: async (id, query) => await this.get('students', 'students', id, query),
    create: async (body, query) => await this.post('students', 'students', query, body),
    patch: async (id, body, query) => {
      return await this.patch('students', 'students', id, query, body)
    },
    remove: async (id, query) => await this.delete('students', 'students', id, query),
  }

  static studentRegistrationsService = {
    find: async (query) => {
      const service = await this.find('student-registrations', 'student-registrations', query)
      return service
    },
    get: async (id, query) => await this.get('student-registrations', 'student-registrations', id, query),
    create: async (body, query) => await this.post('student-registrations', 'student-registrations', query, body),
    patch: async (id, body, query) => {
      return await this.patch('student-registrations', 'student-registrations', id, query, body)
    },
    remove: async (id, query) => await this.delete('student-registrations', 'student-registrations', id, query),
  }

  static serviceMap = {
    books: this.booksService,
    instructors: this.instructorsService,
    'reference-data': this.referenceDataService,
    'reference-data-master': this.referenceDataMasterService,
    'skills-map': this.skillsMapService,
    'core-skills': this.coreSkillsService,
    'career-paths': this.careerPathsService,
    'sections': this.sectionsService,
    'courses': this.coursesService,
    'students': this.studentsService,
    'student-registrations': this.studentRegistrationsService
  };

  static service(name) {
    return this.serviceMap[name];
  }
}

export let journeyInstructors
export let journeyReferenceData
export let journeyReferenceDataMaster
export let journeySkillsMap
export let journeyCoreSkills
export let journeyCareerPath
export let journeyCategories
export let journeySections
export let journeyCourses
export let journeyStudents
export let journeyStudentRegistrations

export const initializeJourneyApiAxiosClient = async () => {
  try {
    journeyInstructors = JourneyApiAxiosClient.service('instructors')
    journeyReferenceData = JourneyApiAxiosClient.service('reference-data')
    journeyReferenceDataMaster = JourneyApiAxiosClient.service('reference-data-master')
    journeySkillsMap = JourneyApiAxiosClient.service('skills-map')
    journeyCoreSkills = JourneyApiAxiosClient.service('core-skills')
    journeyCareerPath = JourneyApiAxiosClient.service('career-paths')
    journeyCategories = JourneyApiAxiosClient.service('categories')
    journeySections = JourneyApiAxiosClient.service('sections')
    journeyCourses = JourneyApiAxiosClient.service('courses')
    journeyStudents = JourneyApiAxiosClient.service('students')
    journeyStudentRegistrations = JourneyApiAxiosClient.service('student-registrations')
  } catch (error) {
    console.error('Error initializing journeyApiAxiosClient', error)
  }
}

// This wrapper requires hooks. Besides, it is intended for authorization, which is still not defined without Feathers. So, better not to use this wrapper.
/*
export const viewApiAxiosClientWrapper = (viewName, serviceName) => ({
  find: (params) => JourneyApiAxiosClient.service(serviceName).find({ ...params, viewName }),
  get: (id, params) => JourneyApiAxiosClient.service(serviceName).get(id, { ...params, viewName }),
  create: (data, params) => JourneyApiAxiosClient.service(serviceName).create(data, { ...params, viewName }),
  update: (id, data, params) => JourneyApiAxiosClient.service(serviceName).update(id, data, { ...params, viewName }),
  patch: (id, data, params) => JourneyApiAxiosClient.service(serviceName).patch(id, data, { ...params, viewName }),
  remove: (id, params) => JourneyApiAxiosClient.service(serviceName).update(id, { ...params, viewName }),
})
*/

export default JourneyApiAxiosClient;
