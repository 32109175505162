import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router} from 'react-router-dom'
import { withIntl } from '../components/ReactIntlProviderWrapper/ReactIntlProviderWrapper'
import Layout from '../components/Layout/Layout'
import { initializeJourneyApiClient, journeyApiClient} from '../services/journeyApiClient'
import loadable from '@loadable/component'
import { withStyles } from '@ellucian/react-design-system/core/styles'
import IndexRoutes from './IndexRoutes'
import { userContext, sessionProfile, tenantContext } from '../services/stateManagement/localMemory'
import { onSuccessfulLogin, onSuccessfulLogout, saveUserProperties } from '../services/stateManagement/stateManagement'
import {colorTextNeutral200 } from '@ellucian/react-design-system/core/styles/tokens'
import Login from './Login'


/*Workaround start
  Workaround to deal with the "page title" created by Experience - NO GUARANTEE THIS COULD WORK ANY SCENARIO
  Keep commented / uncommented until it is REALLY necessary (e.g. when finally Experience does not solve the issue and production is needed)
  case a: remove the page title, or...
  case b: if not removed, change the background color of page title to be the white-not-exactly-white used by Journey
*/

    // case a: 
    if (process.env.REACT_APP_JOURNEY_DEPLOYMENT_TYPE === 'experience') {
      // Get the parent element by its id
      const parentElement = window.document.getElementById('maincontent');
      // Check if the parent element exists
      if (parentElement) {
        // Get the first child div element
        const firstChildDiv = parentElement.querySelector('div');
        // Check if the first child div element exists
        if (firstChildDiv) {
          // Hide the first child div element
          firstChildDiv.style.display = 'none';
        } else {
          console.error('First child div not found');
        }
      } else {
        console.error('Parent element not found');
      }
    }

    // case b:
    // if(process.env.REACT_APP_JOURNEY_DEPLOYMENT_TYPE === 'experience') {
    //   const parentElement = window.document.getElementById('maincontent');
    //   if (parentElement) {
    //         parentElement.style.backgroundColor=colorTextNeutral200
    //   }
    // }

/* End workaround */

const styles = () => ({
  loginBox:{
    position: "absolute",
    top: "2",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "999",
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },

  // Tip while developing the different header styles: use the following to to visualize the area limits
  //    border: '2px solid rgba(0, 255, 0, 0.5)'   // (green)
  //    border: '1px solid blue', etc..

  // SIDE REGULAR - menu icons on the left
  sideRegularMain: {
    overflow: 'hidden',
    display: 'flex',
    // height: 'calc(100vh - something px)' is calculated on rendering (see useEffect)
  },
  sideRegularLeft: {
    overflow: 'hidden',        // No scroll, so be aware when too many items in header or when the window is very small that items will be not visible
    flex: '0 0 57.5px',        // Fixed container width for the side header
  },
  sideRegularRight: {
    flex: '1',                // Take up the remaining width for the content
    overflowX: 'hidden',      // Make the content scrollable, but hide horizontal bar
    overflowY: 'overflow',
    minWidth: '922px',         // Below this limit I do not need to be responsive
    backgroundColor: colorTextNeutral200
  },

  // TOP REGULAR - Text menu on the top
  topRegularMain: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    // height: 'calc(100vh - something px)' is calculated on rendering (see useEffect)
  },
  topRegularUp: {
    backgroundColor: colorTextNeutral200
  },
  topRegularDown: {
    border: '1px solid coral',// to help while designing; to be removed at the end
    overflowX: 'hidden',      // Make the content scrollable, but hide horizontal bar
    overflowY: 'auto',
    minWidth: '922px',         // Below this limit I do not need to be responsive
    backgroundColor: colorTextNeutral200
  },

  // SIDE SMALL - the menu on the side turns into burguer menu on the top
  sideSmallMain: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  sideSmallUp: {
    backgroundColor: colorTextNeutral200
  },
  sideSmallDown: {
    overflowX: 'hidden',      // Make the content scrollable, but hide horizontal bar
    overflowY: 'auto',
    flex: '1',
    backgroundColor: colorTextNeutral200
  },

  // TOP SMALL - text menu turns to icon menu
  topSmallMain: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  topSmallUp: {
    backgroundColor: colorTextNeutral200
  },
  topSmallDown: {
    overflowX: 'hidden',      // Make the content scrollable, but hide horizontal bar
    overflowY: 'auto',
    backgroundColor: colorTextNeutral200
  },
})

const WINDOW_RESIZE_BREAKPOINT = 922 // (pixels) // TODO: get the value from env variable

// keep the following global, otherwise they are not taken inside the windows handler
let smallWinSizeX=false
let currentUserPropertiesX = {}

const JourneyPage = (props) => {
  const {
    classes,
    pageInfo: { basePath },
    userInfo: { tenantId },
  } = props

  const [isAuthenticated, setIsAuthenticated] = useState(undefined)
  const [verticalOffset, setVerticalOffset] = useState()
  const [smallWinSize, setSmallWinSize] = useState(window.innerWidth < WINDOW_RESIZE_BREAKPOINT)
  const [openLogin, setOpenLogin] = useState(false)


  //// Initialization
  //////////////////////////////////////////////////

  useEffect(async() => { // Note that I need the tenantId in place before the api client is ready for checkAuthentication()
    const theTenantContext = await tenantContext.get(0)
    theTenantContext.tenantId = tenantId
    theTenantContext.institutionName = '' // TODO, read the record 'institution' from db
    await tenantContext.update(0, theTenantContext)
    initializeJourneyApiClient().then(() => checkAuthentication())
  }, [])

  const checkAuthentication = async () => {
    try {
      const auth = await journeyApiClient.reAuthenticate()
      await onSuccessfulLogin(auth)
    } catch (error) {
      if (error.name.includes('NotAuthenticated')) {
        await onSuccessfulLogout()
      }
      else throw error
    }
  }


  // Listeners
  // Using clean up function in the useEffect to prevent duplication after re-mounting
  /////////////////////////////////////////////////

  useEffect(() => { // catch window resizing
    function handleResize() {
      const smallSize = (window.innerWidth < WINDOW_RESIZE_BREAKPOINT) ? true : false
      if (smallWinSizeX != smallSize) {
        smallWinSizeX = smallSize
        setSmallWinSize(smallSize)
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      setSmallWinSize(undefined)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // catch main container div position on rendering (do not use '[]')
  useEffect(()=>{
    const testDiv = document.getElementById("indexdiv")
    setVerticalOffset(testDiv?.offsetTop)
  })

  useEffect(() => { // catch window termination
    const handleSuddenlyClosed = () => {
      // this handler closes right away, so do not use async functions inside, so do not use onPropertiesSavePoint(), call directly saveUserProperties()
      // event.preventDefault() // if you uncomment this line you will get a browser message confirmation before leaving the page; good for debug
      saveUserProperties(currentUserPropertiesX)
    }  
    window.addEventListener("beforeunload", handleSuddenlyClosed)
    return async () => window.removeEventListener("beforeunload", handleSuddenlyClosed)
  }, [])

  useEffect(() => { // catch user changes after onSuccessfulLogin() or onSuccessfulLogout()
    const effect = (theUserContext) => {
      if (theUserContext.loggedIn == undefined) return
      setIsAuthenticated(theUserContext.loggedIn)
    }
    userContext.get(0).then(effect)
    userContext.on('updated', effect)
    return () => {
      setIsAuthenticated(undefined)
      userContext.removeListener('updated', effect)
    }
  }, [])

  useEffect(() => { // keep a copy of the user properties ready to be used anytime by saveUserProperties
    const effect = (theSessionProfile) => {
      currentUserPropertiesX = theSessionProfile.userProperties
    }
    sessionProfile.get(0).then(effect)
    sessionProfile.on('updated', effect)
    return () => sessionProfile.removeListener('updated', effect)
  }, [])


  // Handlers
  /////////////////////////////////////////////////

  const loginFromMenu = () => {
    setOpenLogin(true)
  }

  const onSuccess = (auth) => {
    setOpenLogin(false)
    onSuccessfulLogin(auth)
  }


  // Main
  /////////////////////////////////////////////////
  
  const MainHeader = loadable(() => import('./headers/MainHeader'))
  const SideHeader = loadable(() => import('./headers/SideHeader'))
  const SmallSideHeader = loadable(() => import('./headers/SmallSideHeader'))
  const SmallMainHeader = loadable(() => import('./headers/SmallMainHeader'))

  const menuOnTheSide = process.env.REACT_APP_JOURNEY_DEPLOYMENT_TYPE != "experience" ? false : true
  const menuOnTheTop = !menuOnTheSide

  if (isAuthenticated == undefined) return null
  return (
    <div id="indexdiv">

      {/* Keep Router in the top, so any component "inside" it, e.g. header, will be able to use 'navigate()' */}
      {/* Layout is a wrapper just for message components (nothing to do with styles nor layouts) */}
      <Router basename={basePath}> 
        <Layout /> 

        {openLogin && (
          <div id="loginBox" className={classes.loginBox} > 
            <Login tenantId={tenantId} onSuccess={onSuccess} ></Login> {/*Todo: do not pass tenantID, take it from context*/}
          </div>
        )}

        {(!openLogin && !menuOnTheTop && !smallWinSize) && ( // SIDE REGULAR
          <div id="sideRegularMain" className={classes.sideRegularMain} style={{height: `calc(100vh - ${verticalOffset}px)` }} >
            <div id="sideRegularLeft" className={classes.sideRegularLeft} >
              <SideHeader loginCheck={!isAuthenticated} loginFromMenu={loginFromMenu}/>
            </div>
            <div id="sideRegularRight" className={classes.sideRegularRight}  >
              <IndexRoutes isAuthenticated={isAuthenticated} {...props} />
            </div>
          </div>
        )}

        {(!openLogin && menuOnTheTop && !smallWinSize) && ( // TOP REGULAR
          <div id="topRegularMain" className={classes.topRegularMain} style={{height: `calc(100vh - ${verticalOffset}px)` }} >
            <div id="topRegularUp" className={classes.topRegularUp} >
              <MainHeader loginCheck={!isAuthenticated} onSuccessfulLogout={onSuccessfulLogout} /> {/* Todo: no need to pass onSuccessfulLogout; do need to pass loginFromMenu*/}
            </div>
            <div id="topRegularDown" className={classes.topRegularDown} >
              <IndexRoutes isAuthenticated={isAuthenticated} {...props} />
            </div>
          </div>
        )}

        {(!openLogin && !menuOnTheTop && smallWinSize) && ( // SIDE SMALL
          <div id="sideSmallMain" className={classes.sideSmallMain} style={{height: `calc(100vh - ${verticalOffset}px)` }} >
            <div id="sideSmallUp" className={classes.sideSmallUp} >
              <SmallSideHeader loginCheck={!isAuthenticated} onSuccessfulLogout={onSuccessfulLogout} />
            </div>
            <div id="sideSmallDown" className={classes.sideSmallDown}  >
              <IndexRoutes isAuthenticated={isAuthenticated} {...props} />
            </div>
          </div>
        )}

        {(!openLogin && menuOnTheTop && smallWinSize) && ( // TOP SMALL
          <div id="topSmallMain" className={classes.topSmallMain} style={{height: `calc(100vh - ${verticalOffset}px)` }} >
            <div id="topSmallUp" className={classes.topSmallUp} >
              <SmallMainHeader loginCheck={!isAuthenticated} onSuccessfulLogout={onSuccessfulLogout} />
            </div>
            <div id="topSmallDown" className={classes.topSmallDown} >
              <IndexRoutes isAuthenticated={isAuthenticated} {...props} />
            </div>
          </div>
        )}

      </Router>
    </div>
  )
}

JourneyPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  pageInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
}

export default withIntl(withStyles(styles)(JourneyPage))
