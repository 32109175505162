import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Routes, Route, useLocation } from 'react-router-dom'
import { withIntl } from '../components/ReactIntlProviderWrapper/ReactIntlProviderWrapper'
import loadable from '@loadable/component'
import Login from './Login'
import { onSuccessfulLogin } from '../services/stateManagement/stateManagement'

const IndexRoutes = (props) => {
  const {
    classes,
    pageInfo: { basePath },
    userInfo: { tenantId },
    isAuthenticated
  } = props

  const [currentPath, setCurrentPath] = useState()
  const [goodAuthPath, setGoodAuthPath] = useState()

  let location = useLocation()

  const CourseCatalogDetails = loadable(() => import('../pages/CourseCatalogDetailsPage'))
  const CourseCatalog = loadable(() => import('../pages/CourseCatalogPage'))
  const CourseDetails = loadable(() => import('../pages/CourseDetailsPage'))
  const Courses = loadable(() => import('../pages/CoursesPage'))
  const FileImport = loadable(() => import('../pages/FileImportPage'))
  const PageNotFound = loadable(() => import('../pages/PageNotFoundPage'))
  const PaymentResult = loadable(() => import('../pages/PaymentResultPage'))
  const ReferenceData = loadable(() => import('../pages/ReferenceDataPage'))
  const ReferenceDetails = loadable(() => import('../pages/ReferenceDetailsPage'))
  const ShoppingCart = loadable(() => import('../pages/ShoppingCartPage'))
  const Skillmap = loadable(() => import('../pages/SkillMapPage'))
  const StudentLanding = loadable(() => import('../pages/StudentLandingPage'))

  const authPaths = [
    "/courses", "/course-details", "/reference-details", "/reference-data", "/file-import", "/shopping-cart", '/payment-result'
  ]

  useEffect(() => {
    const thePath = location.pathname
    setCurrentPath(thePath)
    setGoodAuthPath(authPaths.includes(thePath) ? true : false)
  }, [location])

  if (isAuthenticated == undefined || currentPath == undefined) return null
  return (
    <Routes>
      <Route path="/" element={<CourseCatalog />} />
      <Route path="/student-landing" element={<StudentLanding />} />
      <Route path='/courseCatalog' element={<CourseCatalog />} />
      <Route path="/skillmap" element={<Skillmap />} />
      <Route path='/courseCatalogDetails' element={<CourseCatalogDetails />} />
      {(isAuthenticated) && (<>
        <Route path='/course-details' element={<CourseDetails />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/reference-details' element={<ReferenceDetails />} />
        <Route path='/reference-data' element={<ReferenceData />} />
        <Route path='/file-import' element={<FileImport />} />
        <Route path='/payment-result' element={<PaymentResult />} />
        <Route path='/shopping-cart' element={<ShoppingCart />} /> 
      </>)}
      {(!isAuthenticated && goodAuthPath) && (
        <Route path={currentPath} element={<Login onSuccess={onSuccessfulLogin} />} />
      )}
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  )
}

IndexRoutes.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  isAuthenticated: PropTypes.bool.isRequired,
  pageInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
}

export default withIntl(IndexRoutes)
